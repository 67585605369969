// Resize

window.onload = function() {
    function resize(e) {
        var widths = window.innerWidth;
        var heights = window.innerHeight;
        var logo_img =  $('header #wrapHead #logo a img')
        var width_logo = logo_img.innerWidth();
        var height_header = $('header').innerHeight();

        //side width for promo block
        var corps_width = $('#products').innerWidth();
        var corps_width_form_contact = $('#formContact .wrap').innerWidth();
        var side_width = (widths - corps_width) / 2;
        var side_width_form_contact = (widths - corps_width_form_contact) / 2;
        $('#promoSlide').css('width', '100%').css({'width' : '+='+side_width, 'left' : - side_width});
        $('#formContact .wrap .vague-rond').css('left', '0').css({'left' : '-='+side_width_form_contact});

        // New product right height need equal left
        var height_left = $('#introNew #left').height();
        $('#introNew #right').css('min-height', height_left);




       // $('#logo').css('width', width_logo);
       // $('nav').css('width', '100%').css('width', '-='+width_logo);

       // nav mobile
       // $('#navPanelMobile').css({'height' : heights - height_header, 'top' : height_header});
    }
    resize();

    window.onresize = function() {
        resize();
    };
}