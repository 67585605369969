$(window).scroll(function(){
  $('.effect').each(function(){
    if(isScrolledIntoView($(this))){
      $(this).addClass('isVisible');
    }
    else{
      //$(this).removeClass('isVisible');
    }
  });
});

function isScrolledIntoView(elem){
    var $elem = $(elem);
    var $window = $(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var elemTop = $elem.offset().top - 100;
    var elemBottom = elemTop + $elem.height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}


// Delete places in singular

// $(document).ready(function(){
// var str = document.getElementById("pers").innerHTML; 
// var res = str.replace(" places", "");
// document.getElementById("pers").innerHTML = res;
// });


// Change row
$(document).ready(function(){
    $('#input_3_6, #input_2_7').attr('rows', '1');

    $.each(jQuery('#input_3_6, #input_2_7'), function() {
    var offset = this.offsetHeight - this.clientHeight;
 
    var resizeTextarea = function(el) {
        $(el).css('height', 'auto').css('height', el.scrollHeight);
    };
    $(this).on('keyup input', function() { resizeTextarea(this); }).removeAttr('data-autoresize');
});
});