$(function () {
	var mesure = window.innerHeight + 400;
	
    $(".prod-by-prod").slice(0, 12).show();
    $("#loadMore").on('click', function (e) {
        e.preventDefault();
        $(".prod-by-prod:hidden").slice(0, 6).slideDown();
        if ($(".prod-by-prod:hidden").length == 0) {
            $("#load").fadeOut('slow');
        }
        $('html,body').animate({
            scrollTop: $(this).offset().top - mesure
        }, 1500);
    });
});