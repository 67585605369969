// $('#loader').css('opacity', '1'),
// $('#wrapperPage').css('opacity', '0');
// $(document).ready(function(event) {
// setTimeout(function() {
// $('#loader').css('opacity', '0'),
// $('#wrapperPage').css('opacity', '1');
// }, 1000);
// setTimeout(function() {
 // $('#loader').css('display', 'none');}, 1000);
// });




/*	<div id="loader">
		<img src="<?php echo $logo['url'];?>" width="" height="" alt="<?php bloginfo('name'); ?>">
	</div>*/