$(document).ready(function(){
  $('#promoList').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'linear',
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
  });
});
$(document).ready(function(){
  $('#promoListMob').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'linear',
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
  });
});
$(document).ready(function(){
  $('#sUsage').slick({
  	infinite: true,
  	slidesToShow: 1,
  	slidesToScroll: 1,
  	fade: true,
  	cssEase: 'linear',
 	  dots: false
  });
});
$(document).ready(function(){
  $('#pSlide').slick({
  	infinite: true,
  	slidesToShow: 1,
  	slidesToScroll: 1,
  	fade: true,
  	cssEase: 'linear',
 	  dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
  });
 });

$(document).ready(function(){
  $('#pSlide2').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'linear',
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
  });
 });


$(document).ready(function(){
  $('#prodGal').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  fade: true,
  asNavFor: '#prodGal-nav',
  infinite: true,
  });
  var count_prod = $('#introUse .wrap #left #prodGal .slick-list .slick-track .slick-slide').length;
  if(count_prod > 3){
    var nb_prod = 3;
    var focuss = true;
  } else if(count_prod == 3){
    var nb_prod = 2;
    var focuss = true;
  } else if(count_prod == 2){
    var nb_prod = 1;
    var focuss = false;
  } else {
    var nb_prod = 1;
    var focuss = false;
  }

  $('#prodGal-nav').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '#prodGal',
    dots: false,
    focusOnSelect: true,
    infinite: true,
  });
});
$(document).ready(function(){
  $('#newSlide').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  fade: true,
  asNavFor: '#newSlideNav',
  infinite: true,
  });
  var count_prod = $('#introNew #left #partSlide #newSlide .slick-list .slick-track .slick-slide').length;
  if(count_prod > 3){
    var nb_prod = 3;
    var focuss = true;
  } else if(count_prod == 3){
    var nb_prod = 2;
    var focuss = true;
  } else if(count_prod == 2){
    var nb_prod = 1;
    var focuss = false;
  } else {
    var nb_prod = 1;
    var focuss = false;
  }
  $('#newSlideM').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'linear',
    dots: false,
    infinite: true,
  });

$('#newSlideNav').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '#newSlide',
  dots: false,
  focusOnSelect: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1281,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }
  ]
});
});

$(document).ready(function(){
  $('#servListContent').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '#servList',
  infinite: true,
  });
 });
$(document).ready(function(){
  var count_slick = $('#servListContent .slick-slide').length;
$('#servList').slick({
  slidesToShow: count_slick,
  slidesToScroll: 1,
  asNavFor: '#servListContent',
  dots: false,
  focusOnSelect: true,
  infinite: true,
  vertical: true
});
});