var divs = $("#listMarques > .marque-par-marque");

if ($(window).width() >= 992) {
	for(var i = 0; i < divs.length; i+=4) {
	  divs.slice(i, i+4).wrapAll("<div class='wrapMarque'></div>");
	}
} else if($(window).width() <= 991 && $(window).width() >= 768){
	for(var i = 0; i < divs.length; i+=3) {
	  divs.slice(i, i+3).wrapAll("<div class='wrapMarque'></div>");
	}
} else if($(window).width() <= 767 && $(window).width() >= 581){
	for(var i = 0; i < divs.length; i+=2) {
	  divs.slice(i, i+2).wrapAll("<div class='wrapMarque'></div>");
	}
} else {
	for(var i = 0; i < divs.length; i+=1) {
	  divs.slice(i, i+1).wrapAll("<div class='wrapMarque'></div>");
	}
}

$(window).on('resize', function() {
	$('.wrapMarque').contents().unwrap();
	var divs = $("#listMarques > .marque-par-marque");

	if ($(window).width() >= 992) {
		for(var i = 0; i < divs.length; i+=4) {
		  divs.slice(i, i+4).wrapAll("<div class='wrapMarque'></div>");
		}
	} else if($(window).width() <= 991 && $(window).width() >= 768){
		for(var i = 0; i < divs.length; i+=3) {
		  divs.slice(i, i+3).wrapAll("<div class='wrapMarque'></div>");
		}
	} else if($(window).width() <= 767 && $(window).width() >= 581){
		for(var i = 0; i < divs.length; i+=2) {
		  divs.slice(i, i+2).wrapAll("<div class='wrapMarque'></div>");
		}
	} else {
		for(var i = 0; i < divs.length; i+=1) {
		  divs.slice(i, i+1).wrapAll("<div class='wrapMarque'></div>");
		}
	}
});