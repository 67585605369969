$("#hamMenu").on("click", function(){
	$(this).toggleClass('active');
	$('#navPanelMobile').fadeToggle('active');
});


// Pop Old
$("#getPopOld").on("click", function(){
	$('#popOld').fadeIn().css('overflow-y', 'scroll');
	$('body').css('overflow', 'hidden');
	$('header').css('z-index', '-1');
});

$("#reserver").on("click", function(){
	$('#popReserve').fadeIn().css('overflow-y', 'scroll');
	$('body').css('overflow', 'hidden');
	$('header').css('z-index', '-1');
});


$("#forClose").on("click", function(){
	$('#popOld').fadeOut().css('overflow-y', 'initial');
	$('body').css('overflow', 'initial');
	$('header').css('z-index', '10');
});
$("#forClosea").on("click", function(){
	$('#popReserve').fadeOut().css('overflow-y', 'initial');
	$('body').css('overflow', 'initial');
	$('header').css('z-index', '10');
});


$("#filterProd").on("click", function(){
	$('.allSpas, #productPageUsage #products #left form').fadeToggle();
});
        /*------------------------------------*\
            MAGIC ONLGET
        \*------------------------------------*/

$('.fonctionalite .tab_content').addClass('open');
$('.fonctionalite .tab').addClass('active');
$('#fonctionnalite').css('height', $('.fonctionalite').height());


$('.bloc_tab .tab').each(function() {
    $(this).click(function() {
    	var heightBlock = $(this).parent().height();
    	$('#fonctionnalite').css('height', heightBlock);
        $('.bloc_tab .tab').removeClass('active');
        $(this).addClass('active');
        $('.bloc_tab .tab_content').removeClass('open');
        $(this).siblings('.tab_content').addClass('open');
    });
});

	var hasClicked = false;
	$('.search-submit').on('click',function(e) {
		  if (hasClicked === true) {
		  //submit form here
		  } else {
			  e.preventDefault();
			  hasClicked = true;
			  $('.search-input').addClass('visible',500);
		 }
	});





        /*------------------------------------*\
            DROPDOWN ARROW ON SELECT FORM
        \*------------------------------------*/

        $('#filterProd').wrap('<div class="arrow_container" style="position:relative"></div>').after('<div class="arrow"><div>')
        $(".arrow_container").click(function() {
            $(this).toggleClass("open")
        })






// $('input_1_10').after('')


// document.getElementById("input_1_10").onchange = function () {
//       document.getElementById("file-placeholder").value = this.value.replace("C:\\fakepath\\", "");
//    };
