
/***
 * $.fn.sortElements
 * --------------
 * @param Function comparator:
 *   Exactly the same behaviour as [1,2,3].sort(comparator)
 *
 * @param Function getSortable
 *   A function that should return the element that is
 *   to be sorted. The comparator will run on the
 *   current collection, but you may want the actual
 *   resulting sort to occur on a parent or another
 *   associated element.
 *
 *   E.g. $('td').sortElements(comparator, function(){
 *      return this.parentNode;
 *   })
 *
 *   The <td>'s parent (<tr>) will be sorted instead
 *   of the <td> itself.
 */
$.fn.sortElements = (function(){
    var sort = [].sort;
    return function(comparator, getSortable) {
        getSortable = getSortable || function(){return this;};
        var placements = this.map(function(){
            var sortElement = getSortable.call(this),
                parentNode = sortElement.parentNode,
                // Since the element itself will change position, we have
                // to have some way of storing its original position in
                // the DOM. The easiest way is to have a 'flag' node:
                nextSibling = parentNode.insertBefore(
                    document.createTextNode(''),
                    sortElement.nextSibling
                );
            return function() {
                if (parentNode === this) {
                    throw new Error(
                        "You can't sort elements if any one is a descendant of another."
                    );
                }
                // Insert before flag:
                parentNode.insertBefore(this, nextSibling);
                // Remove flag:
                parentNode.removeChild(nextSibling);
            };
        });
        return sort.call(this, comparator).each(function(i){
            placements[i].call(getSortable.call(this));
        });
    };
})();












$('.dropdown select').on('change',function(){
    $('#promoSlide2').css('display','none');
	if($(this,'option').val() == "decroissant"){

// $(".prod-by-prod").sortElements(function(a, b){
//     return $(a).data('price')>$(b).data('price')?1:-1;
// });

$(".prod-by-prod").sortElements(function(a, b){
    return $(a).data('year')<$(b).data('year')?1:-1;
// $('#promoSlide2').prepend( $('#loadMore') );

});

  $('#promoSlide2').insertAfter($('.prod-by-prod:nth-child(4) '));
    console.log($('.prod-by-prod:nth-child(4) '))

}
if($(this,'option').val() == "croissant"){

// $(".prod-by-prod").sortElements(function(a, b){
//     return $(a).data('price')<$(b).data('price')?1:-1;
// });


$(".prod-by-prod").sortElements(function(a, b){
    return $(a).data('year')>$(b).data('year')?1:-1;
  
});

}






})

