

if($('header').hasClass('front')){
var height_windows = window.innerHeight;
var height_header = $('header').innerHeight();
var stickyTop = $('header').offset().top + height_windows + height_header;


$(window).on( 'scroll', function(){
	if($('header, #supportHead').hasClass('head-is-Scroll')){}else{
	if ($(window).scrollTop() >= stickyTop) {
		$('header, #supportHead').addClass('head-is-Scroll');
	} else {
		$('header, #supportHead').removeClass('head-is-Scroll');
	}
}
});

function checkScroll(){
	$(window).on( 'scroll', function(){
		if ($(window).scrollTop() >= height_windows - height_header) {
			$('header, #supportHead').addClass('head-is-Scroll');
		} else {
			$('header, #supportHead').removeClass('head-is-Scroll');
		}
	});
}


$(document).ready(function() {
	checkScroll();
	$(window).scroll(checkScroll);
});

}
